let inited = false;

export enum GaEvents {
  SoundTherapyEnd = "SoundTherapyEnd",
  SoundTherapyStart = "SoundTherapyStart",
  SessionStart = "SessionStart",
  SessionEnd = "SessionEnd",
  AiTalkStart = "AiTalkStart",
  AiTalkEnd = "AiTalkEnd",
  PersonTalkStart = "PersonTalkStart",
  PersonTalkEnd = "PersonTalkEnd",
  AiThinkingStart = "AiThinkingStart",
  AiThinkingEnd = "AiThinkingEnd",
  AiThinkingEndProblem = "AiThinkingEndProblem",
  ChooseFeedback = "ChooseFeedback",
}

declare global {
  interface Window { dataLayer: any; }
}

function getScript(gtmId: string): string {
  return "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':" +
    "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0]," +
    "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=" +
    "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);" +
    `})(window,document,'script','dataLayer',"${gtmId}");` +
    "<!-- End Google Tag Manager -->";
}

function init(gtmId: string) {
  if (!inited) {
    const script = document.createElement('script');
    script.innerHTML = getScript(gtmId);
    document.head.insertBefore(script, document.head.childNodes[0]);
    inited = true;
  }
}

function sendEvent(event: GaEvents, data: any = {}) {
  console.log('sendEvent', event, data);
  if (window.dataLayer) {
    window.dataLayer.push({...data, event})
  }
}

const gtm = {
  init,
  sendEvent
}

export default gtm
